@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./images/image1.png');
  background-repeat: no-repeat;
  background-position: center;
  position: relative; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .bgImgCenter{
  background-image: url('./images/3334896.jpg');
  background-repeat: no-repeat;
  background-position: center; 
  position: relative;
} */